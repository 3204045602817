import React from "react"
import { useUser } from "@oneauxilia/oneauxilia-react"
import { Button } from "antd"
export default function DashboardPage() {
  const { user } = useUser()
  function onClick(params) {
    window.location.href = "https://console-client-dev.oneauxilia.co/"
  }
  return (
    <div className="py-24">
      <div className="text-3xl mb-6 font-bold">Welcome, {user?.fullName}</div>
      <p className="text-gray-400 mb-8">
        You are signed in. Now, it's time to connect OneAuxilia to your application.
      </p>
      <div>
        <Button size="large" type="primary" onClick={onClick}>
          Start building
        </Button>
        <div className="fixed bottom-9 right-0 max-w-[450px]">
          <img
            alt="banner"
            src="https://sgp1.vultrobjects.com/oneauxilia-homepage-image/images/8223f3b9-a4d9-4e0a-9257-682eef0f6a10"
          />
        </div>
      </div>
    </div>
  )
}
