import React from "react"
import { SignedIn, UserButton } from "@oneauxilia/oneauxilia-react"
import styles from "./styles.module.css"
import { Outlet } from "react-router-dom"

export default function Layout() {
  return (
    <div>
      <header className={styles.ox_header}>
        <div className="max-w-screen-xl flex justify-between m-auto items-center p-4">
          <div>{icLogo}</div>
          <div className="flex">
            <SignedIn>
              <UserButton pathSetting="/user" />
            </SignedIn>
          </div>
        </div>
      </header>
      {/* dsas */}
      <main className="max-w-screen-xl m-auto p-4 mt-[70px]">
        <Outlet />
      </main>
    </div>
  )
}

const icLogo = (
  <svg width="156" height="24" viewBox="0 0 209 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_250_5710)">
      <path
        d="M27.3258 6.42518L19.4457 1.87915C17.1071 0.533121 14.2347 0.533121 11.8961 1.87915L4.01601 6.42518C1.6774 7.77122 0.253906 10.2601 0.253906 12.9268V22.0188C0.253906 24.7109 1.6774 27.1998 4.01601 28.5458L9.53206 31.7204C9.91336 31.949 10.4217 31.7204 10.498 31.2633L11.3369 26.1331C11.3877 25.803 11.2352 25.4474 10.9301 25.2696C8.18482 23.6188 6.38003 20.5458 6.53255 17.0665C6.73591 12.3426 10.6251 8.50773 15.3786 8.35534C20.5642 8.20296 24.8092 12.3426 24.8092 17.4728C24.8092 20.749 23.0807 23.6442 20.4625 25.2442C20.1575 25.422 20.0049 25.7776 20.0812 26.1077L20.9709 31.1871C21.0471 31.6442 21.5555 31.8728 21.9368 31.6442L27.3258 28.5458C29.6644 27.1998 31.0879 24.7109 31.0879 22.0188V12.9268C31.0879 10.2601 29.639 7.77122 27.3258 6.42518Z"
        fill="black"
      />
      <path
        d="M19.8531 17.0409C20.1073 19.6821 17.8956 21.917 15.2518 21.6377C13.2944 21.4345 11.6929 19.8599 11.4895 17.9043C11.2099 15.2377 13.4469 13.0282 16.0907 13.2821C18.0736 13.4853 19.6497 15.0599 19.8531 17.0409Z"
        fill="url(#paint0_linear_250_5710)"
      />
      <path
        d="M35.562 31.5682V10.5143H40.5443V12.8C42.0949 11.1492 44.3826 10.1079 46.8737 10.1079C51.7543 10.1079 55.1351 13.5619 55.1351 18.8952V31.5682H50.1529V19.9619C50.1529 16.4317 48.2972 14.7809 45.6536 14.7809C42.7812 14.7809 40.5443 16.8889 40.5443 20.2158V31.5936H35.562V31.5682Z"
        fill="black"
      />
      <path
        d="M69.4971 10.1079C75.4707 10.1079 79.5633 14.4762 79.5633 20.7492C79.5633 21.5365 79.4362 22.4254 79.3599 23.2635H63.676C64.5149 25.8793 66.8789 27.4793 70.0055 27.4793C72.0391 27.4793 73.971 26.7682 75.242 25.3714L78.394 28.5206C76.3604 30.7555 73.4117 31.9746 69.9547 31.9746C63.5998 31.9746 58.7446 27.2254 58.7446 21.0285C58.7446 14.8317 63.371 10.1079 69.4971 10.1079ZM63.5998 19.4031H74.8861C74.4539 16.4063 72.2424 14.5524 69.4209 14.5524C66.4976 14.5778 64.0065 16.5079 63.5998 19.4031Z"
        fill="black"
      />
      <path
        d="M80.4023 31.5684L91.9429 1.27002H97.3826L108.923 31.5684H103.56L101.17 25.2954H88.1553L85.7659 31.5684H80.4023ZM99.4162 20.343L94.6882 7.92399L89.9347 20.343H99.4162Z"
        fill="black"
      />
      <path
        d="M115.583 10.5142V22.1205C115.583 25.6253 117.438 27.3015 120.082 27.3015C122.955 27.3015 125.191 25.1427 125.191 21.8665V10.5142H130.174V31.5681H125.191V29.2824C123.641 30.9332 121.353 31.9745 118.862 31.9745C113.981 31.9745 110.601 28.5205 110.601 23.1872V10.5142H115.583Z"
        fill="black"
      />
      <path
        d="M132.944 31.5681L141.257 20.6729L133.554 10.5396H139.757L144.358 16.5078L148.959 10.5142H155.161L147.434 20.6729L155.746 31.5681H149.518L144.383 24.838L139.198 31.5681H132.944Z"
        fill="black"
      />
      <path
        d="M161.008 0.837891C162.66 0.837891 163.957 2.18392 163.957 3.78392C163.957 5.43472 162.66 6.78075 161.008 6.78075C159.356 6.78075 158.059 5.43472 158.059 3.78392C158.059 2.18392 159.356 0.837891 161.008 0.837891ZM158.517 31.568V10.5141H163.499V31.568H158.517Z"
        fill="black"
      />
      <path d="M173.362 31.5682H168.379V0H173.362V31.5682Z" fill="black" />
      <path
        d="M180.734 0.837891C182.386 0.837891 183.682 2.18392 183.682 3.78392C183.682 5.43472 182.386 6.78075 180.734 6.78075C179.081 6.78075 177.785 5.43472 177.785 3.78392C177.785 2.18392 179.081 0.837891 180.734 0.837891ZM178.242 31.568V10.5141H183.225V31.568H178.242Z"
        fill="black"
      />
      <path
        d="M197.281 10.1079C199.925 10.1079 202.391 11.5047 203.763 13.1301V10.5143H208.746V31.5682H203.763V28.8762C202.365 30.6031 199.925 32 197.281 32C191.257 32 186.834 27.073 186.834 21.0539C186.834 15.0349 191.257 10.1079 197.281 10.1079ZM191.791 21.0539C191.791 24.3301 194.485 27.0222 197.764 27.0222C201.044 27.0222 203.738 24.3301 203.738 21.0539C203.738 17.7778 201.044 15.0857 197.764 15.0857C194.511 15.0603 191.791 17.7524 191.791 21.0539Z"
        fill="black"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_250_5710"
        x1="11.4165"
        y1="17.4583"
        x2="19.9"
        y2="17.4583"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A900FF" />
        <stop offset="1" stop-color="#8C06F9" />
      </linearGradient>
      <clipPath id="clip0_250_5710">
        <rect width="209" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
