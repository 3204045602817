import React from "react"
import "@oneauxilia/oneauxilia-react/dist/index.css"
import Router from "./routes"
import "./App.css"

export default function App() {
  return (
    <div>
      <Router />
    </div>
  )
}
